import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/jonespen/projects/urbaninfrastructure/react-ui-kit/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Props, Playground } from "docz";
import { Bike as BikeIcon } from "@urbaninfrastructure/react-icons";
import AllIcons from "./components/AllIcons";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "icons"
    }}>{`Icons`}</h1>
    <p>{`A collection of all icons used, exported as React components.`}</p>
    <h2 {...{
      "id": "getting-started"
    }}>{`Getting started`}</h2>
    <p>{`Install `}<inlineCode parentName="p">{`@urbaninfrastructure/react-icons`}</inlineCode>{` from NPM by running `}<inlineCode parentName="p">{`yarn add @urbaninfrastructure/react-icons`}</inlineCode>{`.`}</p>
    <p>{`The icon components can then be imported from `}<inlineCode parentName="p">{`@urbaninfrastructure/react-icons`}</inlineCode>{`,
Remember, only import the icons you need:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import { Bike as BikeIcon } from '@urbaninfrastructure/react-icons'

function App() (
  <BikeIcon color="primary" />
)
`}</code></pre>
    <p>{`Icons can be customized using `}<inlineCode parentName="p">{`color`}</inlineCode>{` and `}<inlineCode parentName="p">{`size`}</inlineCode>{`:`}</p>
    <h2 {...{
      "id": "example"
    }}>{`Example`}</h2>
    <Playground __position={0} __code={'<BikeIcon color=\"state.error\" />\n<BikeIcon color=\"primary\" size=\"68px\" />'} __scope={{
      props,
      DefaultLayout,
      Props,
      Playground,
      BikeIcon,
      AllIcons,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <BikeIcon color="state.error" mdxType="BikeIcon" />
  <BikeIcon color="primary" size="68px" mdxType="BikeIcon" />
    </Playground>
    <h2 {...{
      "id": "small-icons"
    }}>{`Small icons`}</h2>
    <p>{`Small icons are optimized for `}<inlineCode parentName="p">{`24px`}</inlineCode>{` sizes.`}</p>
    <AllIcons large={false} mdxType="AllIcons" />
    <h2 {...{
      "id": "large-icons"
    }}>{`Large icons`}</h2>
    <p>{`Large icons are optimized for larger presentations. Use these for page illustrations. They have uneven gutters inside the SVG which makes them bad options for use in buttons.`}</p>
    <AllIcons large={true} mdxType="AllIcons" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      